// consumer_t_od_hotel_order_interim_hotelPolicyTips   差标检测-个人差标提示
const __request = require(`./__request/__request_contentType_json`)
const consumer_t_od_hotel_order_interim_hotelPolicyTips = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/hotelPolicyTips',
        data: data
    }
    return __request(pParameter)
}
export default consumer_t_od_hotel_order_interim_hotelPolicyTips
