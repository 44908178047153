import consumer_flight_search_getVerification from '@/lib/data-service/haolv-default/consumer_flight_search_getVerification'   // 超标提示标签
import consumer_trains_product_trainsPolicyTips from '@/lib/data-service/haolv-default/consumer_trains_product_trainsPolicyTips'   // 火车票差标检测-个人差标提示
import consumer_t_od_hotel_order_interim_hotelPolicyTips from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelPolicyTips'   // 差标检测-个人差标提示

export default {
    data () {
        return {
            loading: false,
            exceedContentList: []
        }
    },
    props: {
        // 业务类型：'flight'-机票，'train'-火车票，'hotel'-酒店
        businessType: {
            type: String,
            default: ''
        },
        // 接口地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/机票%20-%20查询/getReserveVerificationUsingPOST
        flightParams: {
            type: Object,
            default: () => {}
        },
        // 接口地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/product-controller/trainsPolicyTipsUsingPOST
        trainParams: {
            type: Object,
            default: () => {}
        },
        // 接口地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/酒店订单/hotelPolicyTipsUsingPOST
        hotelParams: {
            type: Object,
            default: () => {}
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {},
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        async getExceedContentList() {
            try {
                this.loading = true
                if (this.businessType === 'flight') {
                    const res = await consumer_flight_search_getVerification(this.flightParams)
                    this.exceedContentList = res.datas
                }
                if (this.businessType === 'train') {
                    const res = await consumer_trains_product_trainsPolicyTips(this.trainParams)
                    this.exceedContentList = res.datas
                }
                if (this.businessType === 'hotel') {
                    const res = await consumer_t_od_hotel_order_interim_hotelPolicyTips(this.hotelParams)
                    this.exceedContentList = res.datas
                }
            } catch (error) {

            } finally {
                this.loading = false
            }
        }
    }
}
