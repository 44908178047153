// consumer_trains_product_trainsPolicyTips   火车票差标检测-个人差标提示
const __request = require(`./__request/__request_contentType_json`)
const consumer_trains_product_trainsPolicyTips = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/trains/product/trainsPolicyTips',
        data: data
    }
    return __request(pParameter)
}
export default consumer_trains_product_trainsPolicyTips
